import API from "../../../utilities/API";

export const getLoanList = async (statusId) => {
  let url = null;
  if (statusId) {
    url = `/api/v1/loan?statusId=${statusId}`;
  } else {
    url = `/api/v1/loan`;
  }

  // const url = `/api/v1/loan?statusId=${statusId}`;
  const response = await API.get(url);

  return response.data;
};

export const getLoanDetails = async (loanId) => {
  const url = `/api/v1/loan/${loanId}`;
  const response = await API.get(url);

  return response.data;
};

export const createLoan = async (payload) => {
  const url = "/api/v1/loan";
  const response = await API.post(url, payload);

  return response.data;
};

export const updateLoan = async (loanId, updatePayload) => {
  const url = `/api/v1/loan/${loanId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};

export const deleteLoan = async (loanId) => {
  const url = `/api/v1/loan/${loanId}`;
  const response = await API.delete(url);

  return response.data;
};

export const getOverAllLoanDetails = async (queryParams = {}) => {
  // const url = `api/v1/loan/overall`;
  // const response = await API.get(url);

  // return response.data;

  let url = `api/v1/loan/overall`;
  if (Object.keys(queryParams).length > 0) {
    console.log(
      "Object.keys(queryParams).length > 0",
      Object.keys(queryParams).length > 0
    );
    url +=
      "?" +
      Object.entries(queryParams)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  }

  const response = await API.get(
    `${url}${Object.keys(queryParams).length > 0 ? `&` : ``}`
  );
  return response?.data;
};

import API from "../../../utilities/API";

export const getCategoriesList = async (queryParams = {}) => {
  // const url = `/api/v1/category?categoryType=${categoryType}&isCombined=${isCombined}`;
  // const response = await API.get(url);

  // return response.data;

  let url = `api/v1/category`;
  if (Object.keys(queryParams).length > 0) {
    url +=
      "?" +
      Object.entries(queryParams)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  }

  const response = await API.get(
    `${url}${Object.keys(queryParams).length > 0 ? `&` : ``}`
  );
  return response?.data;
};

export const getCategoriesDetails = async (categoryId) => {
  const url = `/api/v1/category/${categoryId}`;
  const response = await API.get(url);

  return response.data;
};

export const createCategories = async (payload) => {
  const url = "/api/v1/category";
  const response = await API.post(url, payload);

  return response.data;
};

export const updateCategories = async (categoryId, updatePayload) => {
  const url = `/api/v1/category/${categoryId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};

export const deleteCategories = async (categoryId) => {
  const url = `/api/v1/category/${categoryId}`;
  const response = await API.delete(url);

  return response.data;
};

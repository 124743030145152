import React from "react";
import { Box, Typography, LinearProgress, Grid } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LinearProgressWithLabel from "views/ui-elements/basic/LinearProgressBar";
import { useNavigate } from "react-router-dom";
import VillaIcon from "@mui/icons-material/Villa";
import {
  checkGoalIsPassedOrNot,
  createImageFromInitials,
  formatDateInSimpleWord,
  formatNumber,
} from "utils/helper";
import IconBox from "./IconBox";
const GoalCard = ({ tag, disabledRedirection, className, ...item }) => {
  const navigate = useNavigate();

  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  return (
    <Box
      px={3}
      py={1}
      bgcolor="rgb(31, 34, 39, 0.75)"
      borderRadius="16px"
      mb={2}
      onClick={() =>
        !disabledRedirection &&
        navigate("/saving-goal-details", { state: { goalId: item?.id } })
      }
      className={
        className ? `${className} position-relative` : "position-relative"
      }
    >
      {item?.associatedAccount && (
        <Box
          width="130px"
          height="30px"
          borderRadius="100px"
          px={1}
          bgcolor="rgb(14 111 117 / 29%)"
          fontWeight="500"
          display="flex"
          fontSize="12px"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "relative",
            top: "13px",
            margin: "auto",
          }}
        >
          {item?.associatedAccount?.name}
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" mt={3}>
        <Box>
          {/* <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="50px"
            height="50px"
            borderRadius="50px"
            bgcolor="#000"
            mr={2}
          >
            <img
              width="45px"
              height="45px"
              className="icon-img-color"
              style={{
                objectFit: "contain",
                borderRadius: "100%",
              }}
              src={
                item?.icon?.url
                  ? item.icon.url
                  : createImageFromInitials(45, item?.name, "rgb(6, 56, 59)")
              }
              alt=""
            />
          </Box> */}
          <IconBox
            bgcolor="#000"
            // className="icosn-img-color icon-box-img-p-3 "

            url={
              item?.icon?.url
                ? item.icon.url
                : createImageFromInitials(45, item?.name, "rgb(6, 56, 59)")
            }
            className="icon-img-color icon-box-img-p-3  object-scale"
            style={{
              height: "27px",
              objectFit: "unset",
            }}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems={tag && "end"}
        >
          <div>
            <Typography
              fontSize="18px"
              variant="h1"
              component="h5"
              color="#fff"
              fontWeight="500"
              align="right"
            >
              {my_finance_user_in_Json &&
                my_finance_user_in_Json.wallet &&
                my_finance_user_in_Json.wallet.length > 0 &&
                my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
              {/* 2300 */}
              {formatNumber(item?.saved_amount || 0)}
            </Typography>
            <Typography
              fontSize="14px"
              mt={0.5}
              color="#fff"
              variant="h2"
              component="h5"
              fontWeight="400"
            >
              of{" "}
              {my_finance_user_in_Json &&
                my_finance_user_in_Json.wallet &&
                my_finance_user_in_Json.wallet.length > 0 &&
                my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
              {formatNumber(item?.goal_amount || 0)}
            </Typography>
          </div>
        </Box>
      </Box>
      <Box>
        <Grid container spacing={2} sx={{ paddingTop: "10px !important" }}>
          <Grid item xs={6}>
            <Typography
              fontSize="16px"
              mt={3}
              variant="h4"
              component="h5"
              color="#fff"
              fontWeight="400"
            >
              {item?.name}
              {/* Buy Villa */}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              fontSize="16px"
              mt={3}
              variant="h4"
              component="h5"
              color="#fff"
              fontWeight="400"
              align="right"
            >
              {item?.goalAchievedInPercentage} %
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "7px !important" }}>
            <LinearProgress
              variant="determinate"
              value={item?.goalAchievedInPercentage || 0}
              color="secondary"
              aria-label='"traffic progress"'
            />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" justifyContent="space-between" mt={3} mb={2}>
        {item?.status?.id === 3 ? (
          <Typography
            fontSize="14px"
            variant="h3"
            component="h3"
            color="#00897b"
            fontWeight="500"
          >
            Acheived
          </Typography>
        ) : (
          <>
            <div>
              <Typography
                fontSize="14px"
                variant="h3"
                component="h3"
                color="#fff"
                fontWeight="500"
              >
                {my_finance_user_in_Json &&
                  my_finance_user_in_Json.wallet &&
                  my_finance_user_in_Json.wallet.length > 0 &&
                  my_finance_user_in_Json?.wallet[0]?.currency?.symbol}{" "}
                {item?.dueAmount || 0}
              </Typography>
              <Typography
                variant="body2"
                component="h6"
                fontWeight="400"
                color="#fff"
                fontSize="13px"
              >
                {/* Expected */}
                Minimum Contribution
              </Typography>
            </div>
            <div>
              <Typography
                fontSize="14px"
                variant="h3"
                component="h3"
                color="#00897b"
                fontWeight="500"
              >
                {item?.target_date
                  ? checkGoalIsPassedOrNot(item?.target_date)
                  : null}

                {/* "Goal is Passed" */}
                {/* 22 June 2023 */}
              </Typography>
              <Typography
                textAlign="right"
                variant="body2"
                component="h6"
                fontWeight="400"
                color="#fff"
                fontSize="13px"
              >
                Actual
              </Typography>
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default GoalCard;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getDebitCardList,
  getDebitCardDetails,
  createDebitCard,
  updateDebitCard,
  deleteDebitCard,
} from "../debitCardServices/debitServices";

const initialState = {
  singleDebitCardDetails: [],
  debitCardLists: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getDebitCardSlice = createAsyncThunk(
  "debitCard/getDebitCardList",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getDebitCardList();
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getDebitCardDetailsSlice = createAsyncThunk(
  "debitCard/getDebitCardDetails",
  async ({ goalId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getDebitCardDetails(goalId);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      console.log("response?.data", response?.data);
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const createDebitCardSlice = createAsyncThunk(
  "debitCard/createDebitCard",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await createDebitCard(payload);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updateDebitCardSlice = createAsyncThunk(
  "debitCard/updateDebitCard",
  async ({ goalId, updatePayload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await updateDebitCard(goalId, updatePayload);

      let filterWallet =
        response &&
        response.data &&
        response.data.length > 0 &&
        response.data.find((item) => item.id === goalId);
      if (moveToNext) {
        moveToNext(filterWallet);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deleteDebitCardSlice = createAsyncThunk(
  "debitCard/deleteDebitCard",
  async ({ goalId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deleteDebitCard(goalId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const debitCardSlice = createSlice({
  name: "debitCard",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getDebitCardSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getDebitCardSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.debitCardLists = action.payload;
        state.singleDebitCardDetails = null;
      })
      .addCase(getDebitCardSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getDebitCardDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getDebitCardDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singleDebitCardDetails = action.payload;
      })
      .addCase(getDebitCardDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleDebitCardDetails = null;
      })

      .addCase(createDebitCardSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createDebitCardSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createDebitCardSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateDebitCardSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateDebitCardSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateDebitCardSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteDebitCardSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteDebitCardSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteDebitCardSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = debitCardSlice.actions;

export default debitCardSlice.reducer;

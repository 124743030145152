import API from "../../../utilities/API";

export const getNotificationsList = async () => {
  let url = null;
  url = `/api/v1/notification`;

  const response = await API.get(url);

  return response.data;
};

export const updateNotification = async (notificationId, updatePayload) => {
  const url = `/api/v1/notification/${notificationId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getLoanList,
  getLoanDetails,
  createLoan,
  updateLoan,
  deleteLoan,
  getOverAllLoanDetails,
} from "../LoanServices/loanServices";

const initialState = {
  singleLoanDetails: [],
  loanLists: [],
  overAllLoanDetails: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getLoanListSlice = createAsyncThunk(
  "loan/getLoanList",
  async ({ statusId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getLoanList(statusId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getLoanDetailsSlice = createAsyncThunk(
  "loan/getLoanDetails",
  async ({ loanId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getLoanDetails(loanId);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const createLoanSlice = createAsyncThunk(
  "loan/createLoan",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await createLoan(payload);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updateLoanSlice = createAsyncThunk(
  "loan/updateLoan",
  async ({ loanId, updatePayload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await updateLoan(loanId, updatePayload);

      let filterWallet =
        response &&
        response.data &&
        response.data.length > 0 &&
        response.data.find((item) => item.id === loanId);
      if (moveToNext) {
        moveToNext(filterWallet);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deleteLoanSlice = createAsyncThunk(
  "loan/deleteLoan",
  async ({ loanId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deleteLoan(loanId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getOverAllLoanDetailsSlice = createAsyncThunk(
  "loan/getOverAllLoanDetails",
  async ({ queryParams, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getOverAllLoanDetails(queryParams);
      if (moveToNext) {
        moveToNext({ ...response?.data });
      }
      return { ...response?.data };
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const loanSlice = createSlice({
  name: "loan",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getLoanListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getLoanListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.loanLists = action.payload;
        // state.singleLoanDetails = null;
      })
      .addCase(getLoanListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getLoanDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getLoanDetailsSlice.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singleLoanDetails = action.payload;
      })
      .addCase(getLoanDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleLoanDetails = null;
      })

      .addCase(createLoanSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createLoanSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createLoanSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateLoanSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateLoanSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateLoanSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteLoanSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteLoanSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteLoanSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getOverAllLoanDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getOverAllLoanDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.overAllLoanDetails = action.payload;
      })
      .addCase(getOverAllLoanDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = loanSlice.actions;

export default loanSlice.reducer;

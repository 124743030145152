import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";
// import OtpCodeScreen from "views/pages/otpCode";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Login3"))
);
const OtpCodeScreen = Loadable(lazy(() => import("views/pages/otpCode")));
const ForgotPasswordOtpCodeScreen = Loadable(
  lazy(() => import("views/pages/otpCode/resetPasswordOtp"))
);
const TwoFactorAuthenticationOtpCodeScreen = Loadable(
  lazy(() => import("views/pages/otpCode/twoFactorAuthentication"))
);
const AuthRegister = Loadable(
  lazy(() => import("views/pages/authentication/authentication3/Register3"))
);
const AuthForgotPassword = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication3/ForgotPassword3")
  )
);
const OnBoard = Loadable(lazy(() => import("views/pages/onBoard")));
const Questionare = Loadable(lazy(() => import("views/pages/questionare")));
const AuthSetup = Loadable(lazy(() => import("views/pages/authSetup")));
const AuthQuestion = Loadable(lazy(() => import("views/pages/authQuestion")));
const PrivacyPolicy = Loadable(lazy(() => import("views/pages/privacyPolicy")));
const EmailVerification = Loadable(
  lazy(() => import("views/pages/emailVerification"))
);
// const PickProfilePicture = Loadable(
//   lazy(() => import("views/pages/pickProfilePicture"))
// );
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    // {
    //   path: "/pick-profile-picture",
    //   element: <PickProfilePicture />,
    // },

    {
      path: "/email-verification",
      element: <EmailVerification />,
    },
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "/login",
      element: <AuthLogin />,
    },
    {
      path: "/register",
      element: <AuthRegister />,
    },
    {
      path: "/otp-code",
      element: <OtpCodeScreen />,
    },
    {
      path: "/reset-password-otp",
      element: <ForgotPasswordOtpCodeScreen />,
    },
    {
      path: "/two-factor-otp",
      element: <TwoFactorAuthenticationOtpCodeScreen />,
    },

    // {
    //   path: "/auth-setup",
    //   element: <AuthSetup />,
    // },

    // {
    //   path: "/auth-questions",
    //   element: <AuthQuestion />,
    // },
    // {
    //   path: "/privacy-policy",
    //   element: <PrivacyPolicy />,
    // },

    {
      path: "/forgot",
      element: <AuthForgotPassword />,
    },
  ],
};

export default LoginRoutes;

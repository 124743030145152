import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserList,
  getUserByCountry,
  createPolicy,
  updatePolicy,
  deletePolicy,
  getPolicyDetails,
  getTransactionDetails,
  getWalletDetails,
  getTransactionGraphData,
} from "../AdminServices/adminServices";

const initialState = {
  singleLoanDetails: [],
  userList: [],
  userListByCountry: [],
  overAllLoanDetails: null,
  transactionDetails: null,
  transactionGraphData: null,
  walletDetails: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getUserListSlice = createAsyncThunk(
  "admin/getUserList",
  async ({ offset, limit, queryParams, moveToNext, onFailure }, thunkAPI) => {
    console.log("runing....");
    try {
      const response = await getUserList(offset, limit, queryParams);
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getUserByCountrySlice = createAsyncThunk(
  "admin/getUserByCountry",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getUserByCountry();
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTransactionDetailsSlice = createAsyncThunk(
  "admin/getTransactionDetails",
  async ({ moveToNext, onFailure, queryParams }, thunkAPI) => {
    try {
      const response = await getTransactionDetails(queryParams);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTransactionGraphDataSlice = createAsyncThunk(
  "admin/getTransactionGraphData",
  async ({ queryParams, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getTransactionGraphData(queryParams);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getWalletDetailsSlice = createAsyncThunk(
  "admin/getWalletDetailsSlice",
  async ({ moveToNext, onFailure, queryParams }, thunkAPI) => {
    try {
      const response = await getWalletDetails(queryParams);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const createPolicySlice = createAsyncThunk(
  "admin/createPolicy",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await createPolicy(payload);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updatePolicySlice = createAsyncThunk(
  "admin/updatePolicy",
  async ({ policyId, updatePayload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await updatePolicy(policyId, updatePayload);

      let filterWallet =
        response &&
        response.data &&
        response.data.length > 0 &&
        response.data.find((item) => item.id === policyId);
      if (moveToNext) {
        moveToNext(filterWallet);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deletePolicySlice = createAsyncThunk(
  "admin/policyId",
  async ({ policyId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deletePolicy(policyId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getPolicyDetailsSlice = createAsyncThunk(
  "admin/getPolicyDetails",
  async ({ policyId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getPolicyDetails(policyId);
      if (moveToNext) {
        moveToNext(response.data);
      }
      return { ...response?.data };
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUserListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getUserListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.userList = action.payload;
        // state.singleLoanDetails = null;
      })
      .addCase(getUserListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getUserByCountrySlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getUserByCountrySlice.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.userListByCountry = action.payload;
      })
      .addCase(getUserByCountrySlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleLoanDetails = null;
      })

      .addCase(getTransactionGraphDataSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getTransactionGraphDataSlice.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.transactionGraphData = action.payload;
      })
      .addCase(getTransactionGraphDataSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleLoanDetails = null;
      })

      .addCase(getWalletDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getWalletDetailsSlice.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.walletDetails = action.payload;
      })
      .addCase(getWalletDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleLoanDetails = null;
      })

      .addCase(getTransactionDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getTransactionDetailsSlice.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.transactionDetails = action.payload;
      })
      .addCase(getTransactionDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleLoanDetails = null;
      })

      .addCase(createPolicySlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createPolicySlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createPolicySlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updatePolicySlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updatePolicySlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updatePolicySlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deletePolicySlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deletePolicySlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deletePolicySlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getPolicyDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getPolicyDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.policyDetailList = action.payload;
      })
      .addCase(getPolicyDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = admin.actions;

export default admin.reducer;

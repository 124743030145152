import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAccountsList,
  getAccountDetails,
  createAccount,
  updateAccount,
  deleteAccount,
  getCardTypeList,
  getCashAccountGraphDetails,
  getAccountTransactionDetails,
} from "../AccountServices/accountsServices";

const initialState = {
  allAccounts: [],
  singleAccountDetails: [],
  accountTypes: [],
  cashAccountGraphDetails: [],
  accountTransactionDetails: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getAccountsListSlice = createAsyncThunk(
  "wallet/getAccountsList",
  async ({ accountTypeId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getAccountsList(accountTypeId);
      console.log("response:::::", response);
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getAccountDetailsSlice = createAsyncThunk(
  "wallet/getAccountDetails",
  async ({ accountId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getAccountDetails(accountId);
      console.log("response", response);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const createAccountListSlice = createAsyncThunk(
  "wallet/createAccount",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await createAccount(payload);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updateAccountSlice = createAsyncThunk(
  "wallet/updateAccount",
  async ({ accountId, updatePayload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await updateAccount(accountId, updatePayload);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deleteAccountSlice = createAsyncThunk(
  "wallet/deleteAccount",
  async ({ accountId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deleteAccount(accountId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getCardTypeListSlice = createAsyncThunk(
  "wallet/getCardTypeList",
  async ({ moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getCardTypeList();
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getCashAccountGraphDetailsSlice = createAsyncThunk(
  "wallet/getCashAccountGraphDetails",
  async ({ accountId, fromDate, toDate, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getCashAccountGraphDetails(
        accountId,
        fromDate,
        toDate
      );
      console.log("response::::>>>>>", response);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getAccountTransactionDetailsSlice = createAsyncThunk(
  "wallet/getAccountTransactionDetails",
  async ({ accountId, fromDate, toDate, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getAccountTransactionDetails(
        accountId,
        fromDate,
        toDate
      );
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const accountSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAccountsListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(getAccountsListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allAccounts = action.payload;
        state.singleAccountDetails = null;
        state.message = "";
      })
      .addCase(getAccountsListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getAccountDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(getAccountDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singleAccountDetails = action.payload;
        state.message = "";
      })
      .addCase(getAccountDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleWalletDetails = null;
      })

      .addCase(createAccountListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(createAccountListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(createAccountListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateAccountSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(updateAccountSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(updateAccountSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteAccountSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(deleteAccountSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(deleteAccountSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getCardTypeListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(getCardTypeListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.accountTypes = action.payload;
        state.message = "";
      })
      .addCase(getCardTypeListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.accountTypes = [];
      })

      .addCase(getCashAccountGraphDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(getCashAccountGraphDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.cashAccountGraphDetails = action.payload;
        state.message = "";
      })
      .addCase(getCashAccountGraphDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.accountTypes = [];
      })

      .addCase(getAccountTransactionDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
      })
      .addCase(getAccountTransactionDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.accountTransactionDetails = action.payload;
        state.message = "";
      })
      .addCase(getAccountTransactionDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.accountTypes = [];
      });
  },
});

export const { setUser, reset, logoutSlice } = accountSlice.actions;

export default accountSlice.reducer;

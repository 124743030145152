import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getWalletsList,
  getWalletsDetails,
  createWallet,
  updateWallet,
  getUserWallet,
  deleteWallet,
} from "../WalletsServices/walletsServices";

const initialState = {
  allWalletList: [],
  singleWalletDetails: [],
  userWallets: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getWalletsListSlice = createAsyncThunk(
  "wallet/getWalletsList",
  async ({ pagination, moveToNext, onFailure }, thunkAPI) => {
    let { page, limit } = pagination;

    try {
      const response = await getWalletsList(page, limit);
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getWalletDetailsSlice = createAsyncThunk(
  "wallet/getWalletsDetails",
  async ({ walletId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getWalletsDetails(walletId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const createWalletListSlice = createAsyncThunk(
  "wallet/createWallet",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await createWallet(payload);
      if (moveToNext) {
        moveToNext([response?.data]);
      }
      return [response?.data];
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updateWalletSlice = createAsyncThunk(
  "wallet/updateWallet",
  async ({ walletId, updatePayload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await updateWallet(walletId, updatePayload);

      if (moveToNext) {
        moveToNext([response?.data]);
      }
      return [response?.data];
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deleteWalletSlice = createAsyncThunk(
  "wallet/deleteWallet",
  async ({ walletId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deleteWallet(walletId);
      if (moveToNext) {
        moveToNext(walletId);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getUserWalletListSlice = createAsyncThunk(
  "wallet/getUserWallet",
  async ({ userId, moveToNext, onFailure }, thunkAPI) => {
    console.log("user", userId);
    try {
      const response = await getUserWallet(userId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getWalletsListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getWalletsListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.allWalletList = action.payload;
        state.singleWalletDetails = null;
      })
      .addCase(getWalletsListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getWalletDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getWalletDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singleWalletDetails = action.payload;
      })
      .addCase(getWalletDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singleWalletDetails = null;
      })

      .addCase(createWalletListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createWalletListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createWalletListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.behaviorQuestions = [];
      })

      .addCase(updateWalletSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateWalletSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateWalletSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteWalletSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteWalletSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteWalletSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getUserWalletListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getUserWalletListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.userWallets = action.payload;
      })
      .addCase(getUserWalletListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.securityQuestions = [];
      });
  },
});

export const { setUser, reset, logoutSlice } = walletSlice.actions;

export default walletSlice.reducer;

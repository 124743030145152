import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  FormHelperText,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import MyReserveIcon from "assets/images/icons/supporting_icons/my-reserve.svg";
import RecomendedIcon from "assets/images/icons/supporting_icons/recomended.svg";
import CalculateIcon from "@mui/icons-material/Calculate";
import { useDispatch, useSelector } from "react-redux";
import { getOverAllLoanDetailsSlice } from "store/loan/loanSlice/loan.slice";
import { formatNumber } from "utils/helper";

const MyDebtCard = (props) => {
  let { fromDate, toDate } = props;
  const dispatch = useDispatch();
  const { overAllLoanDetails, isError, isSuccess, message, isLoading } =
    useSelector((state) => state.loan);

  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  useEffect(() => {
    if (fromDate && toDate) {
      dispatch(
        getOverAllLoanDetailsSlice({
          queryParams: {
            fromDate: fromDate,
            toDate: toDate,
          },

          moveToNext: () => {},
          onFailure: () => {},
        })
      );
    } else {
      dispatch(
        getOverAllLoanDetailsSlice({
          queryParams: {},
          moveToNext: () => {},
          onFailure: () => {},
        })
      );
    }
  }, []);
  console.log("overAllLoanDetails", overAllLoanDetails);

  return (
    <Box
      px={3}
      py={3}
      bgcolor="rgb(31, 34, 39, 0.75)"
      borderRadius="16px"
      mb={2}
    >
      {isLoading === true ? (
        <CircularProgress
          sx={{
            color: "#0C7076",
            margin: "auto",
            display: "block",
          }}
        />
      ) : isLoading === false &&
        isError === false &&
        isSuccess === true &&
        overAllLoanDetails ? (
        <>
          <Box
            borderRadius="14px"
            borderRight="6px"
            px={0.5}
            py={1}
            bgcolor="#16333838"
          >
            <Typography
              textAlign="center"
              fontSize="14px"
              component="h4"
              fontWeight="500"
              color="#fff"
            >
              Minimum Due{" "}
              {/* {my_finance_user_in_Json &&
                my_finance_user_in_Json.wallet &&
                my_finance_user_in_Json.wallet.length > 0 &&
                my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
              {overAllLoanDetails?.dueAmount}{" "} */}
              <span
                style={{
                  color: "#00897b",
                }}
              >
                {my_finance_user_in_Json &&
                  my_finance_user_in_Json.wallet &&
                  my_finance_user_in_Json.wallet.length > 0 &&
                  my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                {formatNumber(overAllLoanDetails?.dueAmount)}
              </span>{" "}
              this Month
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="50px"
                height="50px"
                borderRadius="50px"
                bgcolor="#000"
                mr={2}
              >
                <CalculateIcon sx={{ color: "#fff" }} />
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <div>
                <Typography
                  fontSize="18px"
                  variant="h1"
                  component="h5"
                  color="#fff"
                  fontWeight="500"
                  align="right"
                >
                  {my_finance_user_in_Json &&
                    my_finance_user_in_Json.wallet &&
                    my_finance_user_in_Json.wallet.length > 0 &&
                    my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                  {formatNumber(overAllLoanDetails?.loanPaid || 0)}
                </Typography>
                <Typography
                  fontSize="14px"
                  mt={0.5}
                  color="#fff"
                  variant="h2"
                  component="h5"
                  fontWeight="400"
                >
                  of{" "}
                  {my_finance_user_in_Json &&
                    my_finance_user_in_Json.wallet &&
                    my_finance_user_in_Json.wallet.length > 0 &&
                    my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                  {formatNumber(overAllLoanDetails?.loanAmount || 0)}
                </Typography>
              </div>
            </Box>
          </Box>
          <Box>
            <Box mb={1} display="flex" justifyContent="space-between">
              <Typography
                fontSize="16px"
                mt={3}
                variant="h4"
                component="h5"
                color="#fff"
                fontWeight="400"
              >
                Overall Debts
              </Typography>
              <Typography
                fontSize="16px"
                mt={3}
                variant="h4"
                component="h5"
                color="#fff"
                fontWeight="400"
                align="right"
              >
                {overAllLoanDetails?.loanPaidInPercentage || 0}%
              </Typography>
            </Box>

            <LinearProgress
              variant="determinate"
              sx={{ marginTop: "4px" }}
              value={overAllLoanDetails?.loanPaidInPercentage || 0}
              color="secondary"
              aria-label='"traffic progress"'
            />
          </Box>
        </>
      ) : (
        <FormHelperText
          error
          id="standard-weight-helper-text--register"
          sx={{ marginY: "10px", textAlign: "center" }}
        >
          {message}
        </FormHelperText>
      )}
    </Box>
  );
};

export default MyDebtCard;

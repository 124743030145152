import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPaymentList,
  getPaymentDetails,
  creatPayment,
  updatePayment,
  deletePayment,
} from "../paymentServices/paymentsServices";

const initialState = {
  singlePaymentDetails: [],
  paymentLists: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getPaymentSlice = createAsyncThunk(
  "payments/getPaymentList",
  async ({ paymentTypeId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getPaymentList(paymentTypeId);
      if (moveToNext) {
        moveToNext(response?.data?.Payment);
      }
      return response?.data?.Payment;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getPaymentDetailsSlice = createAsyncThunk(
  "payments/getPaymentDetails",
  async ({ paymentId, moveToNext, onFailure }, thunkAPI) => {
    console.log("paymentId", paymentId);
    try {
      const response = await getPaymentDetails(paymentId);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      console.log("response?.data", response?.data);
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const createPaymentSlice = createAsyncThunk(
  "payments/creatPayment",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await creatPayment(payload);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updatePaymentSlice = createAsyncThunk(
  "payments/updatePayment",
  async ({ paymentId, updatePayload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await updatePayment(paymentId, updatePayload);

      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deletePaymentSlice = createAsyncThunk(
  "payments/deletePayment",
  async ({ paymentId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deletePayment(paymentId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPaymentSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getPaymentSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.paymentLists = action.payload;
        state.singlePaymentDetails = null;
      })
      .addCase(getPaymentSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getPaymentDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getPaymentDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.singlePaymentDetails = action.payload;
      })
      .addCase(getPaymentDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.singlePaymentDetails = null;
      })

      .addCase(createPaymentSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createPaymentSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createPaymentSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updatePaymentSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updatePaymentSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updatePaymentSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deletePaymentSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deletePaymentSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deletePaymentSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = paymentSlice.actions;

export default paymentSlice.reducer;

import EditIcon from "assets/images/icons/Grey/edit_icon.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "ui-component/modal/deleteModal";

import React, { useState } from "react";
import MobileScreenLayout from "layout/MobileScreenLayout";
import {
  Box,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { Typography } from "@mui/material";
import Transger_light from "assets/images/Transger_light.png";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import styled from "@emotion/styled";
import Button from "views/ui-elements/basic/Button";
import InputField from "views/ui-elements/basic/InputField";
import useScreenWidth from "hooks/useScreenWidth";
import { LOG_TYPE } from "config";
import {
  addCoverPaymentSlice,
  deleteTransactionSlice,
} from "store/transaction/transactionSlice/transaction.slice";
import { formatDateInSimpleWord, formatNumber, notify } from "utils/helper";
import DeleteIcon from "@mui/icons-material/Delete";
import CoverIcon from "assets/images/cover.svg";
const ICON_BOX_PROPS = {
  width: "70px",
  height: "70px",

  borderRadius: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const DETAIL_CONTAINER = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  my: 1,
};

const BUTTON_PROPS = {
  variant: "contain",
  bg: "#0C7076",
  color: "#fff",
  radius: "13px",
  width: "100%",
  height: "45px",
  mx: 2,
  sx: {
    display: "block",

    mt: "20px",
    py: "8px",
    px: "15px",
    fontWeight: "500",
    fontSize: "16px",
  },
};

// import CustomDateTime from 'views/forms/components/DateTime/CustomDateTime';

const Account = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [deleteModalShow, setDeleteModalShow] = useState(false);

  const locationState = location.state || {};
  console.log("locationState", locationState?.transactionDetails);
  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  const { isLoading, isSuccess } = useSelector((state) => state.transaction);

  const navigate = useNavigate();

  const onConfirm = () => {
    if (locationState?.transactionDetails?.id) {
      dispatch(
        deleteTransactionSlice({
          id: locationState?.transactionDetails?.id,
          moveToNext: deleteSuccessfully,
          onFailure: deleteSuccessFailure,
        })
      );
    }
  };

  const deleteSuccessfully = (transactionDetails) => {
    navigate(-1);
    notify("Transaction deleted succesfully");
  };
  const deleteSuccessFailure = (transactionDetails) => {
    notify("Transaction deleted Error", "error");
  };

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <MobileScreenLayout
      topBar={
        <TopNavigationBar onDeleteHandler={() => setDeleteModalShow(true)} />
      }
      subTittle={"Cash Acc"}
    >
      <Box
        mx={2}
        bgcolor="#1C1C1E"
        position="relative"
        display="flex"
        justifyContent="center"
        flexDirection="center"
        my={3}
        px={2}
        borderRadius="16px"
      >
        <Box
          {...ICON_BOX_PROPS}
          width="85px"
          height="85px"
          position="absolute"
          top="-30px"
        >
          <Box
            {...ICON_BOX_PROPS}
            border="2px solid #00897b"
            style={{
              background: "#000",
              borderColor:
                locationState?.transactionDetails?.transactionType?.id ===
                LOG_TYPE.EXPENSE
                  ? "rgb(255, 173, 173)"
                  : locationState?.transactionDetails?.transactionType?.id ===
                    LOG_TYPE.INCOME
                  ? "rgb(202, 255, 191)"
                  : "#fff",
            }}
          >
            <img
              className={
                locationState?.transactionDetails?.transactionType?.id !==
                LOG_TYPE.TRANSFER
                  ? "icon-img-color-white"
                  : ""
              }
              src={
                locationState?.transactionDetails?.transactionType?.id !==
                LOG_TYPE.TRANSFER
                  ? locationState?.transactionDetails?.category?.icon?.url
                  : CoverIcon
              }
              style={{
                width: "50px",
                height: "30px",
                borderRadius: "100%",
              }}
              alt="dumy"
            />
          </Box>
        </Box>

        <Box width="100%" sx={{ marginTop: "80px" }}>
          <div>
            <Typography
              textAlign="center"
              fontSize="12px"
              component="h6"
              color="#fff"
              fontWeight="500"
            >
              Amount
            </Typography>
            <Typography
              textAlign="center"
              fontSize="40px"
              fontWeight="500"
              component="h6"
              color={
                locationState?.transactionDetails?.transactionType?.id ===
                LOG_TYPE.EXPENSE
                  ? "rgb(255, 173, 173)"
                  : locationState?.transactionDetails?.transactionType?.id ===
                    LOG_TYPE.INCOME
                  ? "rgb(202, 255, 191)"
                  : "#fff"
              }
            >
              {my_finance_user_in_Json &&
                my_finance_user_in_Json.wallet &&
                my_finance_user_in_Json.wallet.length > 0 &&
                my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
              {formatNumber(
                locationState?.transactionDetails?.transaction_amount || 0
              )}
            </Typography>
            {locationState?.transactionDetails?.cover_payment && (
              <Typography
                textAlign="center"
                fontSize="12px"
                component="h6"
                color="#fff"
                fontWeight="300"
                // rgb(0 137 123 / 46%)
                sx={{
                  background: "rgb(0 137 123 / 46%)",
                  borderRadius: "5px",
                  padding: "5px",
                  width: "fit-content",
                  margin: "auto",
                }}
              >
                <b>Amount Covered </b>{" "}
                {my_finance_user_in_Json &&
                  my_finance_user_in_Json.wallet &&
                  my_finance_user_in_Json.wallet.length > 0 &&
                  my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                {locationState?.transactionDetails?.cover_payment || 0}
                {/* USD */}
                {/* {my_finance_user_in_Json &&
              my_finance_user_in_Json.wallet &&
              my_finance_user_in_Json.wallet.length > 0 &&
              my_finance_user_in_Json?.wallet[0]?.currency?.name} */}
              </Typography>
            )}
          </div>
          <Box py={4}>
            <Box {...DETAIL_CONTAINER}>
              <Typography fontSize="14px" fontWeight="500" color="#fff">
                {/* From Account: */}
                {locationState?.transactionDetails.transactionType?.id ===
                  LOG_TYPE.INCOME && (
                  <>
                    {locationState?.transactionDetails?.toAccount && (
                      <>To Account</>
                    )}
                    {locationState?.transactionDetails?.toCreditCard && (
                      <>To Credit Card</>
                    )}
                    {locationState?.transactionDetails?.toGoal && <>To Goal</>}
                    {locationState?.transactionDetails?.toLoan && <>To Loan </>}
                    {locationState?.transactionDetails?.toReserve && (
                      <>To Reserve </>
                    )}
                  </>
                )}
                {locationState?.transactionDetails.transactionType?.id ===
                  LOG_TYPE.EXPENSE && (
                  <>
                    {locationState?.transactionDetails?.fromAccount && (
                      <>From Account</>
                    )}
                    {locationState?.transactionDetails?.fromCreditCard && (
                      <>From Credit Card</>
                    )}
                    {locationState?.transactionDetails?.fromGoal && (
                      <>From Goal</>
                    )}
                    {locationState?.transactionDetails?.fromLoan && (
                      <>From Loan</>
                    )}
                    {locationState?.transactionDetails?.fromReserve && (
                      <>From Reserve</>
                    )}
                  </>
                )}

                {locationState?.transactionDetails.transactionType?.id ===
                  LOG_TYPE.TRANSFER && (
                  <>
                    {locationState?.transactionDetails?.fromAccount && (
                      <>From Account</>
                    )}
                    {locationState?.transactionDetails?.fromCreditCard && (
                      <>From Credit Card</>
                    )}
                    {locationState?.transactionDetails?.fromGoal && (
                      <>From Goal</>
                    )}
                    {locationState?.transactionDetails?.fromLoan && (
                      <>From Loan</>
                    )}
                    {locationState?.transactionDetails?.fromReserve && (
                      <>From Reserve</>
                    )}
                  </>
                )}
              </Typography>
              <Typography fontSize="16px" fontWeight="500" color="#fff">
                {/* UAE/ Cash Account */}

                {locationState?.transactionDetails?.transactionType?.id ===
                  LOG_TYPE.INCOME && (
                  <>
                    {locationState?.transactionDetails?.toAccount && (
                      <>{locationState?.transactionDetails?.toAccount?.name}</>
                    )}
                    {locationState?.transactionDetails?.toCreditCard && (
                      <>
                        {locationState?.transactionDetails?.toCreditCard?.name}
                      </>
                    )}
                    {locationState?.transactionDetails?.toGoal && (
                      <>{locationState?.transactionDetails?.toGoal?.name}</>
                    )}
                    {locationState?.transactionDetails?.toLoan && (
                      <>{locationState?.transactionDetails?.toLoan?.name}</>
                    )}
                    {locationState?.transactionDetails?.toReserve && (
                      <>{locationState?.transactionDetails?.toReserve?.name}</>
                    )}
                  </>
                )}
                {locationState?.transactionDetails?.transactionType?.id ===
                  LOG_TYPE.EXPENSE && (
                  <>
                    {locationState?.transactionDetails?.fromAccount && (
                      <>
                        {locationState?.transactionDetails?.fromAccount?.name}
                      </>
                    )}
                    {locationState?.transactionDetails?.fromCreditCard && (
                      <>
                        {
                          locationState?.transactionDetails?.fromCreditCard
                            ?.name
                        }
                      </>
                    )}
                    {locationState?.transactionDetails?.fromGoal && (
                      <>{locationState?.transactionDetails?.fromGoal?.name}</>
                    )}
                    {locationState?.transactionDetails?.fromLoan && (
                      <>{locationState?.transactionDetails?.fromLoan?.name}</>
                    )}
                    {locationState?.transactionDetails?.fromReserve && (
                      <>
                        {locationState?.transactionDetails?.fromReserve?.name}
                      </>
                    )}
                  </>
                )}
                {locationState?.transactionDetails?.transactionType?.id ===
                  LOG_TYPE.TRANSFER && (
                  <>
                    {locationState?.transactionDetails?.fromAccount && (
                      <>
                        {locationState?.transactionDetails?.fromAccount?.name}
                      </>
                    )}
                    {locationState?.transactionDetails?.fromCreditCard && (
                      <>
                        {
                          locationState?.transactionDetails?.fromCreditCard
                            ?.name
                        }
                      </>
                    )}
                    {locationState?.transactionDetails?.fromGoal && (
                      <>{locationState?.transactionDetails?.fromGoal?.name}</>
                    )}
                    {locationState?.transactionDetails?.fromLoan && (
                      <>{locationState?.transactionDetails?.fromLoan?.name}</>
                    )}
                  </>
                )}
              </Typography>
            </Box>

            {locationState?.transactionDetails.transactionType?.id ===
              LOG_TYPE.TRANSFER && (
              <Box {...DETAIL_CONTAINER}>
                <Typography fontSize="16px" fontWeight="500" color="#fff">
                  <>
                    {locationState?.transactionDetails?.toAccount && (
                      <>To Account</>
                    )}
                    {locationState?.transactionDetails?.toCreditCard && (
                      <>To Credit Card</>
                    )}
                    {locationState?.transactionDetails?.toGoal && <>To Goal</>}
                    {locationState?.transactionDetails?.toLoan && <>To Loan</>}
                    {locationState?.transactionDetails?.toReserve && (
                      <>To Reserve</>
                    )}
                  </>
                </Typography>
                <Typography fontSize="16px" fontWeight="500" color="#fff">
                  {locationState?.transactionDetails?.toAccount && (
                    <>{locationState?.transactionDetails?.toAccount?.name}</>
                  )}
                  {locationState?.transactionDetails?.toCreditCard && (
                    <>{locationState?.transactionDetails?.toCreditCard?.name}</>
                  )}
                  {locationState?.transactionDetails?.toGoal && (
                    <>{locationState?.transactionDetails?.toGoal?.name}</>
                  )}
                  {locationState?.transactionDetails?.toLoan && (
                    <>{locationState?.transactionDetails?.toLoan?.name}</>
                  )}
                  {locationState?.transactionDetails?.toReserve && (
                    <>{locationState?.transactionDetails?.toReserve?.name}</>
                  )}
                </Typography>
              </Box>
            )}

            <Box {...DETAIL_CONTAINER}>
              <Typography fontSize="14px" fontWeight="500" color="#fff">
                Category:
              </Typography>
              <Typography fontSize="16px" fontWeight="500" color="#fff">
                {locationState?.transactionDetails?.category?.name || "N/A"}
              </Typography>
            </Box>

            <Box {...DETAIL_CONTAINER}>
              <Typography fontSize="14px" fontWeight="500" color="#fff">
                Date:
              </Typography>
              <Typography fontSize="16px" fontWeight="500" color="#fff">
                {formatDateInSimpleWord(
                  locationState?.transactionDetails?.transaction_date
                ) || ""}
              </Typography>
            </Box>

            {/* <Box {...DETAIL_CONTAINER}>
              <Typography fontSize="14px" fontWeight="500" color="#fff">
                Time:
              </Typography>
              <Typography fontSize="16px" fontWeight="500" color="#fff">
                2:28pm
              </Typography>
            </Box> */}
          </Box>
          <Box my={4}>
            <Typography fontSize="14px" fontWeight="500" color="#fff">
              <span>Note:</span>{" "}
              {locationState?.transactionDetails?.transaction_description || ""}
            </Typography>
          </Box>
        </Box>
      </Box>

      {locationState?.transactionDetails?.transactionType?.id ===
        LOG_TYPE.EXPENSE && (
        <Box
          bgcolor="#1C1C1E"
          display="flex"
          alignItems="center"
          mx={2}
          p={2}
          borderRadius="16px"
        >
          <Box
            width="40px"
            height="40px"
            bgcolor="#000"
            borderRadius="50px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <AccountBalanceIcon sx={{ color: "#00897b", fontSize: "20px" }} />
          </Box>

          <Box
            ml={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ flex: 1 }}
          >
            <div>
              <Typography
                variant="h4"
                component="h3"
                color="#fff"
                fontWeight="400"
              >
                Cover Payment
              </Typography>

              <Typography
                variant="body2"
                component="h3"
                color="#fff"
                fontWeight="300"
              >
                Got paid back for this expense? Log it here.
              </Typography>
            </div>

            <ExpandCircleDownIcon
              onClick={() => setDrawerOpen(true)}
              sx={{ color: "#00897b", transform: "rotate(270deg)" }}
            />
          </Box>
        </Box>
      )}

      {/* <Box p={2}>
        <Button
          {...BUTTON_PROPS}
          sx={BUTTON_PROPS.sx}
          onClick={() => setDeleteModalShow(true)}
        >
          Delete
        </Button>
      </Box> */}

      {drawerOpen && (
        <DrawerComponent
          transactionDetails={locationState?.transactionDetails}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      )}

      {deleteModalShow && (
        <DeleteModal
          isClodeIcon={false}
          show={deleteModalShow}
          onHide={() => setDeleteModalShow(false)}
          tittle={""}
          onConfirm={onConfirm}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      )}
    </MobileScreenLayout>
  );
};

export default Account;

const TopNavigationBar = ({
  headerbgcolor,
  onDeleteHandler,
  subTittle,
  children,
}) => {
  const navigate = useNavigate();

  const HEADER_LEFT_RIGHT_CIRCLE = {
    width: "40px",
    height: "40px",
    bgcolor: "rgb(31, 34, 39, 0.75)",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box
      sx={{ display: { xs: "flex" } }}
      height="40px"
      // display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={2}
      py={3}
      boxSizing="content-box"
      borderRadius="20px 20px 0px 0px"
      bgcolor={headerbgcolor ? headerbgcolor : "transparent"}
      className="mobile-header"
    >
      <Box {...HEADER_LEFT_RIGHT_CIRCLE} onClick={() => navigate(-1)}>
        <KeyboardArrowLeftIcon sx={{ fontSize: "30px", color: "#fff" }} />
      </Box>
      <div>
        <Typography
          textAlign="center"
          fontSize="18px"
          variant="h3"
          component="h6"
          color="#fff"
          fontWeight="500"
        >
          Transaction History
        </Typography>
      </div>

      <Box {...HEADER_LEFT_RIGHT_CIRCLE}>
        {/* <img src={EditIcon} alt="" /> */}
        <DeleteIcon onClick={() => onDeleteHandler()} />
      </Box>
    </Box>
  );
};

const DrawerComponent = ({ drawerOpen, setDrawerOpen, transactionDetails }) => {
  const screenWidth = useScreenWidth();
  const [report, setReport] = useState("What is your favorite color");
  const { isLoading, isSuccess } = useSelector((state) => state.transaction);
  const navigate = useNavigate();
  const [coverPayment, setCoverPayment] = useState(null);

  const handleChange = (event) => {
    setReport(event.target.value);
  };

  const INPUT_FIELD_PROPS_SEARCH = {
    label: "",
    fullWidth: true,
    variant: "standard",
  };

  const INPUT_FIELD_STYLES_SEARCH = {
    sx: {
      height: "50px",
      paddingLeft: "10px",
      "& fieldset": { border: "1px solid #fff", borderRadius: "8px" },
      "& input": { background: "none" },
    },
  };

  const dispatch = useDispatch();

  const addCoverPaymentHandler = () => {
    if (coverPayment?.length > 0 && transactionDetails) {
      let finalPayload = {
        transactionAmount: parseFloat(coverPayment),
        // notes: "This is the test notes", // optional
        // attachments: ["d3578da6-0fcb-4b0d-9b6c-3ad3ab8741a3"], // optional
      };
      dispatch(
        addCoverPaymentSlice({
          id: transactionDetails?.id,
          payload: finalPayload,
          moveToNext,
          onFailure: failureHandler,
        })
      );
    } else {
      notify("Please add ammount", "error");
    }
  };

  const moveToNext = () => {
    notify("Cover Payment add");
    setDrawerOpen(false);
    navigate(-1);
  };

  const failureHandler = () => {
    notify("Error Occure while record payment", "error");
  };
  return (
    <Drawer
      anchor={screenWidth < 768 ? "bottom" : "right"}
      open={drawerOpen}
      sx={{
        width: "300px",

        "& .MuiPaper-root": {
          backgroundColor: "#2b2b33 !important",
          borderRadius: screenWidth < 768 ? "24px 24px 0px 0px" : "0px",
          p: 2,
        },
      }}
      onClose={() => setDrawerOpen(false)}
    >
      <Box width="100%" py={1}>
        <Typography
          textAlign="center"
          fontSize="16px"
          fontWeight="500"
          color="#fff"
        >
          Cover Payment
        </Typography>
        <Box marginTop="20px">
          {/* <Typography my={2} fontSize="12px" fontWeight="700" color="#fff">
              Select Name
            </Typography>
  
            <StyledSelect
              inputProps={{ "aria-label": "Without label" }}
              fullWidth={true}
              value={report}
              label=""
              input={<OutlinedInput color="secondary" />}
              onChange={handleChange}
              sx={{
                height: "50px",
                // "& fieldset": {
                //   border: "1px solid #fff",
                // },
              }}
            >
              <MenuItem value={"Male"}>Option 1</MenuItem>
              <MenuItem value={"Female"}>Optio 2</MenuItem>
            </StyledSelect> */}

          {/* <FormControl
            fullWidth
            color="secondary"
            sx={{
              "&.MuiFormControl-root": {
                margin: 0,
              },
            }}
          >
            <InputLabel id="demo-simple-select-label">Select Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Name"
              color="secondary"
            >
              <MenuItem value={"Male"}>Option 1</MenuItem>
              <MenuItem value={"Female"}>Optio 2</MenuItem>
            </Select>
          </FormControl> */}
        </Box>

        <div>
          {/* <Typography my={2} fontSize="12px" fontWeight="500" color="#fff">
              Ammount Covered
            </Typography> */}

          {/* <InputField
              id="country"
              placeholder="Ammount Covered"
              {...INPUT_FIELD_PROPS_SEARCH}
              sx={INPUT_FIELD_STYLES_SEARCH.sx}
              variant="standard"
            /> */}

          <InputField
            id="country"
            type="number"
            placeholder="Ammount Covered"
            label="Ammount Covered"
            fullWidth={true}
            onChange={(e) => setCoverPayment(e.target.value)}
          />
        </div>
      </Box>

      {isLoading === true ? (
        <div style={{ textAlign: "center" }}>Saving....</div>
      ) : (
        <Button
          {...BUTTON_PROPS}
          sx={BUTTON_PROPS.sx}
          onClick={() => addCoverPaymentHandler()}
        >
          Save
        </Button>
      )}
    </Drawer>
  );
};

// material-ui
import { useTheme } from "@mui/material/styles";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
import logoDark from "assets/images/logo.png";
import logo from "assets/images/logo.png";
const Logo = ({ width }) => {
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
     *
     */
    <img
      src={theme.palette.mode === "dark" ? logoDark : logo}
      alt="Berry"
      width={`${width ? width : "50"}`}
    />
  );
};

export default Logo;

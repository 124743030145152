import axios from "axios";
import { refreshToken } from "store/auth/AuthServices/authServices";

// Check if user data is stored in local storage
const localStorageUser = localStorage.getItem("serviceToken");

let headers = {
  Accept: "application/json",
  "ngrok-skip-browser-warning": 69420,
};

if (localStorageUser) headers.Authorization = `Bearer ${localStorageUser}`;

const api = axios.create({
  baseURL: "https://api-finance.growthaccess.ae/",
  // baseURL: "https://1cc7-94-202-25-166.ngrok-free.app",
  headers,
});

const refreshTokenFunc = async (error) => {
  try {
    // Perform the logic to refresh the token
    // Example:
    const refreshedToken = await refreshToken();
    // Update the headers with the new token
    headers.Authorization = `Bearer ${refreshedToken}`;
    // Retry the request
    return api.request(error.config);
  } catch (error) {
    // Handle the error if token refresh fails
    throw error;
  }
};

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      return await refreshTokenFunc(error);
      //   notify("Your session has expired. Please re-login", "error");
    }

    let { message } = error.response.data;

    if (!message) {
      message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }

    const errors = {
      errors: error.response.data.errors,
      message,
    };

    // toast.error(message);

    throw errors;
  }
);

export default api;

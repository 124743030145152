import API from "../../../utilities/API";

export const getTransactionReport = async (queryParams = {}) => {
  // // 2024-02-18 - 2024-02-29
  // let url = `/api/v1/report?fromDate=2024-02-18&toDate=2024-02-29&type=${type}`;

  // const response = await API.get(url);

  // return response.data;

  let url = `/api/v1/report`;
  if (Object.keys(queryParams).length > 0) {
    url +=
      "?" +
      Object.entries(queryParams)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  }

  const response = await API.get(
    `${url}${Object.keys(queryParams).length > 0 ? `&` : ``}`
  );
  return response?.data;
};

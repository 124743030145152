import API from "../../../utilities/API";

export const getCreditCardList = async () => {
  const url = `/api/v1/creditCard`;
  const response = await API.get(url);

  return response.data;
};

export const getCreditCardDetails = async (creditCardId) => {
  console.log("creditCardId", creditCardId);
  const url = `/api/v1/creditCard/${creditCardId}`;
  const response = await API.get(url);

  return response.data;
};

export const createCreditCard = async (payload) => {
  const url = "/api/v1/creditCard";
  const response = await API.post(url, payload);

  return response.data;
};

export const updateCreditCard = async (creditCardId, updatePayload) => {
  const url = `/api/v1/creditCard/${creditCardId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};

export const deleteCreditCard = async (creditCardId) => {
  const url = `/api/v1/creditCard/${creditCardId}`;
  const response = await API.delete(url);

  return response.data;
};

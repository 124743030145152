import { useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import ModerateRoute from "./ModerateRoute";
import AdminnRoutes from "./AdminRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  console.log(">>>>LL>>>", my_finance_user_in_strng, my_finance_user_in_Json);

  // return useRoutes([{ path: '/', element: <PagesLanding /> }, AuthenticationRoutes, LoginRoutes, MainRoutes]);
  return useRoutes([
    LoginRoutes,
    ModerateRoute,
    AuthenticationRoutes,
    my_finance_user_in_Json?.role_id === 2 ? MainRoutes : AdminnRoutes,
  ]);
}

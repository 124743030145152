import API from "../../../utilities/API";

export const getGoalList = async (queryParams = {}) => {
  // let url = null;
  // if (statusId) {
  //   url = `/api/v1/goal?statusId=${statusId}`;
  // } else {
  //   url = `/api/v1/goal`;
  // }
  // const response = await API.get(url);

  // return response.data;

  let url = `/api/v1/goal`;
  if (Object.keys(queryParams).length > 0) {
    url +=
      "?" +
      Object.entries(queryParams)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  }

  const response = await API.get(
    `${url}${Object.keys(queryParams).length > 0 ? `&` : ``}`
  );
  return response?.data;
};

export const getGoalDetails = async (goalId) => {
  const url = `/api/v1/goal/${goalId}`;
  const response = await API.get(url);

  return response.data;
};
export const getOverAllGoalDetails = async (queryParams = {}) => {
  // const url = `/api/v1/goal/overall`;
  // const response = await API.get(url);

  // return response.data;

  let url = `/api/v1/goal/overall`;
  if (Object.keys(queryParams).length > 0) {
    console.log(
      "Object.keys(queryParams).length > 0",
      Object.keys(queryParams).length > 0
    );
    url +=
      "?" +
      Object.entries(queryParams)
        .filter(([, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
  }

  const response = await API.get(
    `${url}${Object.keys(queryParams).length > 0 ? `&` : ``}`
  );
  return response?.data;
};

export const createGoal = async (payload) => {
  const url = "/api/v1/goal";
  const response = await API.post(url, payload);

  return response.data;
};

export const updateGoal = async (goalId, updatePayload) => {
  const url = `/api/v1/goal/${goalId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};

export const deleteGoal = async (goalId) => {
  const url = `/api/v1/goal/${goalId}`;
  const response = await API.delete(url);

  return response.data;
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getWalletsList,
  getWalletsDetails,
  createTransaction,
  updateWallet,
  getUserWallet,
  deleteWallet,
  getTransactionList,
  getTransactionBrief,
  addCoverPayment,
  deleteTransaction,
} from "../TransactionServices/transactionServices";
import { refreshToken } from "store/auth/AuthServices/authServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  transactionBriefList: null,
  message: "",
  transactionsList: [],
  transactionBriefListForLastWeek: null,
};

export const createTransactionSlice = createAsyncThunk(
  "transaction/createTransaction",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await createTransaction(payload);
      if (moveToNext) {
        moveToNext([response?.data]);
      }
      return [response?.data];
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTransactionListSlice = createAsyncThunk(
  "transaction/getTransaction",
  async ({ queryParams }, thunkAPI) => {
    console.log("queryParams", queryParams);
    try {
      const response = await getTransactionList(queryParams);

      return response?.data?.Transaction;

      return;
    } catch (error) {
      if (error?.message === "Request failed with status code 403") {
        // const response = await refreshToken();
        // console.log("response", response);
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTransactionBriefSlice = createAsyncThunk(
  "transaction/getTransactionBrief",
  async ({ fromDate, toDate }, thunkAPI) => {
    try {
      const response = await getTransactionBrief(fromDate, toDate);
      return response?.data;
    } catch (error) {
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
export const getTransactionBriefSliceForPreviousWeek = createAsyncThunk(
  "transaction/getTransactionBriefSliceForPreviousWeek",
  async ({ fromDate, toDate }, thunkAPI) => {
    try {
      const response = await getTransactionBrief(fromDate, toDate);
      return response?.data;
    } catch (error) {
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const addCoverPaymentSlice = createAsyncThunk(
  "transaction/addCoverPaymentSlice",
  async ({ id, payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await addCoverPayment(id, payload);
      if (moveToNext) {
        moveToNext([response?.data]);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deleteTransactionSlice = createAsyncThunk(
  "transaction/deleteTransactionSlice",
  async ({ id, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deleteTransaction(id);
      if (moveToNext) {
        moveToNext([response?.data]);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(createTransactionSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createTransactionSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createTransactionSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getTransactionBriefSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getTransactionBriefSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.transactionBriefList = action.payload;
      })
      .addCase(getTransactionBriefSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getTransactionBriefSliceForPreviousWeek.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(
        getTransactionBriefSliceForPreviousWeek.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.isError = false;
          state.transactionBriefListForLastWeek = action.payload;
        }
      )
      .addCase(
        getTransactionBriefSliceForPreviousWeek.rejected,
        (state, action) => {
          state.isLoading = false;
          state.isSuccess = false;
          state.isError = true;
          state.message = action.payload;
        }
      )

      .addCase(getTransactionListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getTransactionListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.transactionsList = action.payload;
      })
      .addCase(getTransactionListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.transactionsList = [];
      })

      .addCase(addCoverPaymentSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(addCoverPaymentSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.transactionsList = action.payload;
      })
      .addCase(addCoverPaymentSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.transactionsList = [];
      })

      .addCase(deleteTransactionSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteTransactionSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.transactionsList = action.payload;
      })
      .addCase(deleteTransactionSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.transactionsList = [];
      });
  },
});

export const { setUser, reset, logoutSlice } = transactionSlice.actions;

export default transactionSlice.reducer;

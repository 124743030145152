import API from "../../../utilities/API";
// import { API_PATH } from "src/common/constants";

export const userRegisterWithEmail = async (userData) => {
  const url = "/api/v1/auth/signup";
  const response = await API.post(url, userData);
  if (response.data.data) {
    const user = response.data.data;
    API.defaults.headers.Authorization = `Bearer ${user.token}`;
    delete user.moduleList;
  }

  return response.data;
};

export const userVerificationViaEmail = async (userData) => {
  const url = "/api/v1/auth/validateOtp";
  const response = await API.post(url, userData);
  if (response.data.data) {
    const user = response.data.data;
    API.defaults.headers.Authorization = `Bearer ${user.token}`;
    delete user.moduleList;
  }

  return response.data;
};

export const getBehaviorQuestions = async () => {
  const url = "/api/v1/auth/behaviorQuestions";
  const response = await API.get(url);
  if (response.data.data) {
    const user = response.data.data;
    API.defaults.headers.Authorization = `Bearer ${user.token}`;
    delete user.moduleList;
  }

  return response.data;
};

export const saveUserBehaviorQuestions = async (payload) => {
  const url = "/api/v1/auth/setUserBehaviorQuestions";
  const response = await API.post(url, { userBehaviourQuestions: payload });
  if (response.data.data) {
    const user = response.data.data;
    API.defaults.headers.Authorization = `Bearer ${user.token}`;
    delete user.moduleList;
  }

  return response.data;
};

export const getSecurityQuestions = async (userData) => {
  const url = "/api/v1/auth/securityQuestions";
  const response = await API.get(url);
  if (response.data.data) {
    const user = response.data.data;
    API.defaults.headers.Authorization = `Bearer ${user.token}`;
    delete user.moduleList;
  }

  return response.data;
};

export const saveSecurityQuestions = async (payload) => {
  const url = "/api/v1/auth/setUserSecurityQuestions";
  const response = await API.post(url, { userSecurityQuestions: payload });
  if (response.data.data) {
    const user = response.data.data;
    API.defaults.headers.Authorization = `Bearer ${user.token}`;
    delete user.moduleList;
  }

  return response.data;
};
export const updateUserProfile = async (payload, userId) => {
  const url = `/api/v1/user/${userId}`;
  const response = await API.put(url, payload);

  return response.data;
};

export const userLogin = async (userData) => {
  const url = "/api/v1/auth/login";
  const response = await API.post(url, userData);
  if (
    response &&
    response.data &&
    response?.data?.userDetail?.is_two_factor_enabled === false
  ) {
    const user = response?.data?.userDetail;

    API.defaults.headers.Authorization = `Bearer ${response?.data?.accessToken}`;
    delete user.moduleList;

    localStorage.setItem("serviceToken", response?.data?.accessToken);
    localStorage.setItem("refreshToken", response?.data?.refreshToken);
    localStorage.setItem(
      "my_finance_user",
      JSON.stringify({
        ...response?.data?.userDetail,
      })
    );
  }

  return response;
};

export const refreshToken = async () => {
  const url = "/api/v1/auth/refreshToken";
  let refreshToken = localStorage.getItem("refreshToken");

  const response = await API.post(url, {
    refreshToken: refreshToken,
  });
  if (response && response.data) {
    console.log("response.data", response?.data);
    API.defaults.headers.Authorization = `Bearer ${response?.data?.accessToken}`;
    localStorage.setItem("refreshToken", response?.data?.refreshToken);
    localStorage.setItem("serviceToken", response?.data?.accessToken);
  }

  return response;
};

export const changePasswordRequest = async (payload) => {
  const url = "/api/v1/auth/requestForgotPassword";
  const response = await API.post(url, payload);

  return response;
};

export const resetPasswordService = async (payload) => {
  const url = "/api/v1/auth/resetPasswordByOtpRequest";
  const response = await API.post(url, payload);

  return response;
};

import React from "react";
import { Box, Typography } from "@mui/material";
import ButtonComponent from "views/ui-elements/basic/Button";
import TransitionsModal from ".";
import DeleteIcon from "@mui/icons-material/Delete";
export default function DeleteModal({
  isLoading,
  isSuccess,
  show,
  onHide,
  onConfirm,
  modalPrimaryText,
  btnText,
  btnLoadText,
  modalSecondaryText,
  icon,
}) {
  return (
    <TransitionsModal show={show} onHide={onHide} isClodeIcon={false}>
      {icon && (
        <Box
          width="65px"
          height="65px"
          bgcolor="rgb(31, 34, 39, 0.75)"
          borderRadius="100px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mx="auto"
        >
          <DeleteIcon sx={{ color: "#974c47", fontSize: "30px" }} />
        </Box>
      )}
      <Typography
        textAlign="center"
        fontSize="24px"
        fontWeight="500"
        varient="span"
        component="p"
        color="#fff"
        mt={4}
        mb={2}
      >
        {modalPrimaryText
          ? modalPrimaryText
          : "Are you sure you want to delete ?"}
      </Typography>

      <Typography
        textAlign="center"
        fontSize="14px"
        fontWeight="500"
        color="#fff"
        mb={2}
      >
        {modalSecondaryText
          ? modalSecondaryText
          : "Data will be removed forever"}
      </Typography>

      <Box my={3}>
        <ButtonComponent
          variant="contain"
          bg="#fff"
          color="rgb(31, 34, 39, 0.75)"
          height="45px"
          radius="12px"
          width="100%"
          sx={{
            border: "1px solid #fff",
            fontSize: "16px",
            fontWeight: "500",
          }}
          my={2}
          disable={isLoading}
          onClick={() => onConfirm()}
        >
          {}
          {isLoading
            ? `${btnLoadText ? btnLoadText : "Deleteing...."}`
            : `${btnText ? btnText : "Remove all data"}`}
        </ButtonComponent>
        <br></br>
        <br></br>
        <ButtonComponent
          variant="contain"
          bg="#0C7076"
          color="#fff"
          height="45px"
          radius="12px"
          width="100%"
          sx={{ marginBottom: "20px" }}
          onClick={() => onHide()}
        >
          No
        </ButtonComponent>
      </Box>
    </TransitionsModal>
  );
}

import API from "../../../utilities/API";

export const getWalletsList = async (page, limit) => {
  const url = `/api/v1/wallet?offset=${page}&limit=${limit}`;
  const response = await API.get(url);

  return response.data;
};

export const getWalletsDetails = async (walletId) => {
  const url = `/api/v1/wallet/${walletId}`;
  const response = await API.post(url);

  return response.data;
};

export const createWallet = async (payload) => {
  const url = "/api/v1/wallet";
  const response = await API.post(url, payload);

  return response.data;
};

export const updateWallet = async (walletId, updatePayload) => {
  const url = `/api/v1/wallet/${walletId}`;
  const response = await API.put(url, updatePayload);

  return response.data;
};
export const deleteWallet = async (walletId) => {
  const url = `/api/v1/wallet/${walletId}`;
  const response = await API.delete(url);

  return response.data;
};

export const getUserWallet = async (userId) => {
  const url = `/api/v1/wallet?userId=${userId}`;
  const response = await API.get(url);

  return response.data;
};

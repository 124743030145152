import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteGalleyPictures,
  getGalleyPictures,
  uploadPictureService,
} from "../GalleryServices/galleryServices";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  iconsCollection: [],
  message: "",
};

//  user Register
export const uploadPictureSlice = createAsyncThunk(
  "auth/uploadPictureSlice",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    console.log("payload", payload);
    try {
      const response = await uploadPictureService(payload);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getGalleyPicturesSlice = createAsyncThunk(
  "auth/getGalleyPicturesSlice",
  async ({ storageType, moveToNext, onFailure }, thunkAPI) => {
    console.log("storageType", storageType);
    try {
      const response = await getGalleyPictures(storageType);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      // if (error?.message === "Request failed with status code 403") {
      //   localStorage.removeItem("serviceToken");
      //   localStorage.removeItem("my_finance_user");
      //   window.location.replace("/");
      // }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deleteGalleyPicturesSlice = createAsyncThunk(
  "auth/deleteGalleyPicturesSlice",
  async ({ fileArray, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deleteGalleyPictures(fileArray);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const gallerySlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadPictureSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(uploadPictureSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(uploadPictureSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getGalleyPicturesSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getGalleyPicturesSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.iconsCollection = action.payload;
      })
      .addCase(getGalleyPicturesSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteGalleyPicturesSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteGalleyPicturesSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.iconsCollection = action.payload;
      })
      .addCase(deleteGalleyPicturesSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = gallerySlice.actions;

export default gallerySlice.reducer;

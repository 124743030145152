import React from "react";
import { Box, Typography } from "@mui/material";

import visaIcon from "assets/images/pages/visa-icon.png";
import AmericanIcon from "assets/images/pages/american-express.svg";
import moneyIcon from "assets/images/pages/footer-master-card.svg";
import { useNavigate } from "react-router-dom";

const DebitCard = ({ route, clickOnCard, className, ...item }) => {
  console.log("item?.name", item);
  const navigate = useNavigate();

  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  return (
    <Box
      px={3}
      py={4}
      bgcolor="rgb(31, 34, 39, 0.75)"
      borderRadius="16px"
      position="relative"
      // onClick={() => (route ? navigate(route) : console.log("ringn.."))}
      onClick={() => clickOnCard(item?.id)}
      className={className ? className : ""}
    >
      <Box display="flex" justifyContent="space-between" pb={5}>
        <div>
          <Typography
            fontSize="12px"
            variant="h3"
            component="h5"
            color="#fff"
            fontWeight="300"
          >
            Available Balance
          </Typography>
          <Typography
            fontSize="20px"
            my={1}
            variant="h1"
            component="h3"
            color="#fff"
            fontWeight="500"
          >
            {my_finance_user_in_Json &&
              my_finance_user_in_Json.wallet &&
              my_finance_user_in_Json.wallet.length > 0 &&
              my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
            8000
          </Typography>
          <Typography
            fontSize="12px"
            variant="h3"
            component="h5"
            color="#fff"
            fontWeight="300"
          >
            {item?.name}
          </Typography>
        </div>
        <div
          style={{
            position: "absolute",
            right: "0px",
            top: "0px",
            width: "100px",
            height: "100px",
            borderLeft: "1px dotted #00897b",
            borderBottom: "3px dotted #00897b",
            borderBottomLeftRadius: "207px 186px",
          }}
        >
          <img
            // AmericanIcon
            // moneyIcon
            src={
              // visaIcon
              item?.cardNetwork?.id === 1
                ? visaIcon
                : item?.cardNetwork?.id === 2
                ? moneyIcon
                : AmericanIcon
            }
            alt="visa-icon"
            style={{
              position: "absolute",
              // right: 8px;
              // top: 20px;

              right: "20px",
              top: "30px",
            }}
          />
        </div>
      </Box>

      <Box display="flex" justifyContent="space-between" mt={2}>
        <div>
          <Typography
            fontSize="18px"
            variant="h2"
            component="h3"
            color="#fff"
            fontWeight="500"
          >
            25 June 2023
          </Typography>
          <Typography
            fontSize="12px"
            my={0.5}
            variant="h3"
            component="h5"
            color="#fff"
            fontWeight="300"
          >
            Payment Date
          </Typography>
        </div>
        <div>
          <Typography
            fontSize="18px"
            variant="h2"
            component="h3"
            color="#00897b"
            fontWeight="500"
          >
            {my_finance_user_in_Json &&
              my_finance_user_in_Json.wallet &&
              my_finance_user_in_Json.wallet.length > 0 &&
              my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
            5000
          </Typography>
          <Typography
            fontSize="12px"
            my={0.5}
            variant="h3"
            component="h5"
            color="#fff"
            fontWeight="300"
          >
            Minimum Due
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default DebitCard;

import { Box, Grid, Typography, LinearProgress } from "@mui/material";
import MobileScreenLayout from "layout/MobileScreenLayout";
import HistoryItemCard from "views/ui-elements/basic/HistoryItemCard";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import DebitCard from "views/ui-elements/basic/CreditCard";
import Button from "views/ui-elements/basic/Button";
import ShortcutCard from "views/ui-elements/basic/ShortcutCard";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AddIcon from "@mui/icons-material/Add";
import TransactionIcon from "assets/images/icons/Grey/transactions.svg";
import Carousel from "react-multi-carousel";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Profile from "assets/images/profile.svg";
import CardTittle from "views/ui-elements/basic/cardTittle";
import ItemDetailCardWithPrice from "views/ui-elements/basic/ItemDetailCardWithPrice";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GoalCard from "views/ui-elements/basic/GoalCard";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CallMadeIcon from "@mui/icons-material/CallMade";
import MyBudgetIcon from "assets/images/icons/supporting_icons/my_budget.svg";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReserveCard from "views/ui-elements/basic/MyReserveCard";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RedeemIcon from "@mui/icons-material/Redeem";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import Setting from "assets/images/icons/Grey/settings.svg";
import AdjustIcon from "@mui/icons-material/Adjust";
import MyDebtCard from "views/ui-elements/basic/MyDebtCard";

import CommingSoon from "assets/images/coming-soon.svg";

const BUTTON_PROPS = {
  variant: "contain",
  bg: "#0C7076",
  color: "#fff",
  radius: "13px",
  width: "100%",
  height: "45px",

  sx: {
    display: "flex",
    alignItems: "center",
    mt: "20x",
    mb: "20px",
    py: "8px",
    px: "15px",
    fontWeight: "500",
    fontSize: "16px",
  },
};

const CARD_PROPS = {
  bgcolor: "rgb(31, 34, 39, 0.75)",
  borderRadius: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  p: 2,
  mb: 2,
};

const MyDebtsCommingSoon = () => {
  const navigate = useNavigate();

  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  return (
    <MobileScreenLayout topBar={<TopNavigationBar />}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // height: "100%",
        }}
      >
        <img
          src={CommingSoon}
          alt=""
          style={{
            margin: "50px 0px",
          }}
        />
        <Typography
          align="center"
          fontSize="30px"
          fontWeight="500"
          color="#fff"
          component="h6"
        >
          Stay tuned! We're creating intelligent debt management.
        </Typography>
      </Box>
      {/* <Box p={2}>
        <Box
          width="130px"
          height="30px"
          borderRadius="100px"
          px={1}
          bgcolor="rgb(14 111 117 / 29%)"
          fontWeight="500"
          display="flex"
          fontSize="13px"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "relative",
            top: "47px",
            margin: "auto",
          }}
        >
          September 2023
        </Box>
        <Box
          bgcolor="rgb(31, 34, 39, 0.75)"
          borderRadius="16px"
          display="flex"
          justifyContent="space-between"
          mb={2}
          px={2}
          sx={{ cursor: "pointer" }}
          py={4}
        >
          <Box sx={{ flex: 1 }}>
            <Box
              width="40px"
              height="40px"
              bgcolor="#000"
              borderRadius="50px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <AutoAwesomeIcon sx={{ fontSize: "20px" }} color="secondary" />
            </Box>

            <Box display="flex" justifyContent="space-between" mt={4} mb={1}>
              <Typography
                mb={0.5}
                fontSize="15px"
                variant="h2"
                component="h3"
                color="#fff"
                fontWeight="300"
              >
                {my_finance_user_in_Json &&
                  my_finance_user_in_Json.wallet &&
                  my_finance_user_in_Json.wallet.length > 0 &&
                  my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                2050 spent of{" "}
                {my_finance_user_in_Json &&
                  my_finance_user_in_Json.wallet &&
                  my_finance_user_in_Json.wallet.length > 0 &&
                  my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                5000
              </Typography>

              <Typography
                mb={0.5}
                fontSize="15px"
                variant="h2"
                component="h3"
                color="#fff"
                fontWeight="300"
              >
                {my_finance_user_in_Json &&
                  my_finance_user_in_Json.wallet &&
                  my_finance_user_in_Json.wallet.length > 0 &&
                  my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                3450 left
              </Typography>
            </Box>

            <LinearProgress
              variant="determinate"
              sx={{ marginTop: "5px" }}
              value={73}
              color="secondary"
              aria-label='"traffic progress"'
            />
          </Box>
        </Box>
        <Box my={3}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box
                px={0.5}
                py={2}
                bgcolor="rgb(31, 34, 39, 0.75)"
                borderRadius="16px"
                textAlign="center"
              >
                <RedeemIcon sx={{ fontSize: "25px", color: "#00897b" }} />

                <Typography
                  fontSize="11px"
                  variant="h1"
                  my={0.5}
                  component="h5"
                  color="#fff"
                  fontWeight="400"
                >
                  Daily Allowance
                </Typography>
                <Typography
                  fontSize="16px"
                  variant="h1"
                  mt={1}
                  component="h5"
                  color="#fff"
                  fontWeight="500"
                >
                  {my_finance_user_in_Json &&
                    my_finance_user_in_Json.wallet &&
                    my_finance_user_in_Json.wallet.length > 0 &&
                    my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                  1000
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                px={1}
                py={2}
                bgcolor="rgb(31, 34, 39, 0.75)"
                borderRadius="16px"
                textAlign="center"
              >
                <RemoveCircleOutlineIcon
                  sx={{ fontSize: "25px", color: "#00897b" }}
                />
                <Typography
                  fontSize="11px"
                  variant="h1"
                  my={0.5}
                  component="h5"
                  color="#fff"
                  fontWeight="400"
                >
                  Spent Today
                </Typography>
                <Typography
                  fontSize="16px"
                  variant="h1"
                  mt={1}
                  component="h5"
                  color="#fff"
                  fontWeight="500"
                >
                  {my_finance_user_in_Json &&
                    my_finance_user_in_Json.wallet &&
                    my_finance_user_in_Json.wallet.length > 0 &&
                    my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                  220
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                px={1}
                py={2}
                bgcolor="rgb(31, 34, 39, 0.75)"
                borderRadius="16px"
                textAlign="center"
              >
                <JoinLeftIcon sx={{ fontSize: "25px", color: "#00897b" }} />
                <Typography
                  fontSize="11px"
                  variant="h1"
                  my={0.5}
                  component="h5"
                  color="#fff"
                  fontWeight="400"
                >
                  Ammount Left
                </Typography>
                <Typography
                  fontSize="16px"
                  variant="h1"
                  mt={1}
                  component="h5"
                  color="#fff"
                  fontWeight="500"
                >
                  {my_finance_user_in_Json &&
                    my_finance_user_in_Json.wallet &&
                    my_finance_user_in_Json.wallet.length > 0 &&
                    my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                  780
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box
                bgcolor="rgb(31, 34, 39, 0.75)"
                borderRadius="16px"
                px={2}
                pt={2}
                pb={0.5}
                textAlign="center"
              >
                <CallMadeIcon sx={{ fontSize: "30px", color: "#CAFFBF" }} />
                <div>
                  <Typography
                    fontSize="18px"
                    fontWeight="500"
                    component="h2"
                    color="#fff"
                    my={1}
                  >
                    Income
                  </Typography>
                  <br></br>
                  <Typography
                    fontSize="17px"
                    fontWeight="400"
                    component="h5"
                    color="#fff"
                  >
                    {my_finance_user_in_Json &&
                      my_finance_user_in_Json.wallet &&
                      my_finance_user_in_Json.wallet.length > 0 &&
                      my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                    500.00
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight="300"
                    component="h5"
                    color="#fff"
                    my={1}
                    sx={{
                      color: "#BDBDBD",
                      position: "relative",
                      top: "-9px",
                    }}
                  >
                    Expected
                  </Typography>
                </div>

                <div>
                  <Typography
                    fontSize="17px"
                    fontWeight="500"
                    component="h5"
                    color="#fff"
                  >
                    {my_finance_user_in_Json &&
                      my_finance_user_in_Json.wallet &&
                      my_finance_user_in_Json.wallet.length > 0 &&
                      my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                    500.00
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight="300"
                    component="h5"
                    color="#fff"
                    my={1}
                    sx={{
                      color: "#BDBDBD",
                      position: "relative",
                      top: "-9px",
                    }}
                  >
                    Actual
                  </Typography>
                </div>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                bgcolor="rgb(31, 34, 39, 0.75)"
                borderRadius="16px"
                px={2}
                pt={2}
                pb={0.5}
                textAlign="center"
              >
                <CallReceivedIcon sx={{ fontSize: "30px", color: "#FFADAD" }} />
                <div>
                  <Typography
                    fontSize="18px"
                    fontWeight="500"
                    component="h2"
                    color="#fff"
                    my={1}
                  >
                    Expense
                  </Typography>
                  <br></br>
                  <Typography
                    fontSize="17px"
                    fontWeight="400"
                    component="h5"
                    color="#fff"
                  >
                    {my_finance_user_in_Json &&
                      my_finance_user_in_Json.wallet &&
                      my_finance_user_in_Json.wallet.length > 0 &&
                      my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                    1500.00
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight="300"
                    component="h5"
                    color="#fff"
                    my={1}
                    sx={{
                      color: "#BDBDBD",
                      position: "relative",
                      top: "-9px",
                    }}
                  >
                    Expected
                  </Typography>
                </div>

                <div>
                  <Typography
                    fontSize="17px"
                    fontWeight="500"
                    component="h5"
                    color="#fff"
                  >
                    {my_finance_user_in_Json &&
                      my_finance_user_in_Json.wallet &&
                      my_finance_user_in_Json.wallet.length > 0 &&
                      my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                    500.00
                  </Typography>
                  <Typography
                    fontSize="14px"
                    fontWeight="300"
                    component="h5"
                    color="#fff"
                    my={1}
                    sx={{
                      color: "#BDBDBD",
                      position: "relative",
                      top: "-9px",
                    }}
                  >
                    Actual
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <CardTittle tittle="Expenses" goTo="" />
          <ItemDetailCardWithPrice />
        </Box>
        <Box my={5}>
          <CardTittle tittle="MyDebts" goTo="/debt" />
          <MyDebtCard />
        </Box>
        <Box my={5}>
          <CardTittle tittle="MyGoals" goTo="/saving-goal" />
          <MyGoalGeneralCard />
        </Box>
        <Box my={5}>
          <CardTittle tittle="MyReserve" goTo="/my-reserve" />
          <ReserveCard />
        </Box>
      </Box> */}
    </MobileScreenLayout>
  );
};

export default MyDebtsCommingSoon;

const TopNavigationBar = ({ headerbgcolor, subTittle, children }) => {
  const navigate = useNavigate();
  const HEADER_LEFT_RIGHT_CIRCLE = {
    width: "40px",
    height: "40px",
    bgcolor: "rgb(31, 34, 39, 0.75)",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Box
      sx={{ display: { xs: "flex" } }}
      height="40px"
      // display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={2}
      py={3}
      boxSizing="content-box"
      borderRadius="20px 20px 0px 0px"
      bgcolor={headerbgcolor ? headerbgcolor : "transparent"}
      className="mobile-header"
    >
      <Box {...HEADER_LEFT_RIGHT_CIRCLE} onClick={() => navigate(-1)}>
        <KeyboardArrowLeftIcon sx={{ fontSize: "30px", color: "#fff" }} />
      </Box>
      <div>
        <Typography
          textAlign="center"
          fontSize="18px"
          variant="h3"
          component="h6"
          color="#fff"
          fontWeight="500"
        >
          MyDebts
        </Typography>
      </div>

      <Box
        {...HEADER_LEFT_RIGHT_CIRCLE}
        bgcolor="transparent"
        // onClick={() => navigate("/manage-budget")}
      >
        {/* <img src={Setting} alt="" /> */}
      </Box>
    </Box>
  );
};

const CardCarousal = ({ children }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 40,
    },
  };
  return (
    <Carousel
      additionalTransfrom={0}
      centerMode={false}
      focusOnSelect={false}
      minimumTouchDrag={80}
      partialVisible
      pauseOnHover
      renderDotsOutside={false}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      sliderClass=""
      // itemClass="mr-2"
      // -------------

      swipeable={true}
      draggable={true}
      showDots={false}
      arrows={false}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={false}
      autoPlaySpeed={3000}
      keyBoardControl
      transitionDuration={500}
      renderButtonGroupOutside
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style"
    >
      {children}
    </Carousel>
  );
};

const MyGoalGeneralCard = () => {
  let my_finance_user_in_strng = localStorage.getItem("my_finance_user");
  let my_finance_user_in_Json = JSON.parse(my_finance_user_in_strng);

  return (
    <Box position="relative">
      <Box
        px={3}
        py={3}
        bgcolor="rgb(31, 34, 39, 0.75)"
        borderRadius="16px"
        mb={2}
        // py={2}
      >
        <Box
          borderRadius="14px"
          borderRight="6px"
          px={0.5}
          py={1}
          bgcolor="#16333838"
        >
          <Typography
            textAlign="center"
            fontSize="14px"
            component="h4"
            fontWeight="500"
            color="#fff"
          >
            Contributed{" "}
            <span
              style={{
                color: "#00897b",
              }}
            >
              {my_finance_user_in_Json &&
                my_finance_user_in_Json.wallet &&
                my_finance_user_in_Json.wallet.length > 0 &&
                my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
              1200.32
            </span>{" "}
            {/* Each Month */}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="50px"
              height="50px"
              borderRadius="50px"
              bgcolor="#000"
              mr={2}
            >
              <AdjustIcon sx={{ color: "#fff" }} />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <div>
              <Typography
                fontSize="18px"
                variant="h1"
                component="h5"
                color="#fff"
                fontWeight="500"
                align="right"
              >
                56300
              </Typography>
              <Typography
                fontSize="14px"
                mt={0.5}
                color="#fff"
                variant="h2"
                component="h5"
                fontWeight="400"
              >
                of{" "}
                {my_finance_user_in_Json &&
                  my_finance_user_in_Json.wallet &&
                  my_finance_user_in_Json.wallet.length > 0 &&
                  my_finance_user_in_Json?.wallet[0]?.currency?.symbol}
                100000
              </Typography>
            </div>
          </Box>
        </Box>
        <Box>
          <Box mb={1} display="flex" justifyContent="space-between">
            <Typography
              fontSize="16px"
              mt={3}
              variant="h4"
              component="h5"
              color="#fff"
              fontWeight="400"
            >
              Overall Goals
            </Typography>
            <Typography
              fontSize="16px"
              mt={3}
              variant="h4"
              component="h5"
              color="#fff"
              fontWeight="400"
              align="right"
            >
              56%
            </Typography>
          </Box>

          <LinearProgress
            variant="determinate"
            value={56}
            sx={{ marginTop: "4px" }}
            color="secondary"
            aria-label='"traffic progress"'
          />
        </Box>
      </Box>
    </Box>
  );
};

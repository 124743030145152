import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getReserveList,
  getReserveDetails,
  createReserve,
  updateReserve,
  deleteReserve,
} from "../reserveServices/reserveServices";

const initialState = {
  reserveDetails: null,
  reserveLists: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getReserveListSlice = createAsyncThunk(
  "reserve/getReserveList",
  async ({ statusId, moveToNext, onFailure }, thunkAPI) => {
    console.log("statusId>>>", statusId);
    try {
      const response = await getReserveList(statusId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response?.data?.Goal;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getReserveDetailsSlice = createAsyncThunk(
  "reserve/getReserveDetails",
  async ({ walletId, reserveId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await getReserveDetails(reserveId, walletId);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      console.log("response?.data", response?.data);
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const createReserveSlice = createAsyncThunk(
  "reserve/createReserve",
  async ({ payload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await createReserve(payload);
      if (moveToNext) {
        moveToNext(response?.data);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }
      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updateReserveSlice = createAsyncThunk(
  "reserve/updateReserve",
  async ({ reserveId, updatePayload, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await updateReserve(reserveId, updatePayload);

      let filterWallet =
        response &&
        response.data &&
        response.data.length > 0 &&
        response.data.find((item) => item.id === reserveId);
      if (moveToNext) {
        moveToNext(filterWallet);
      }
      return response?.data;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const deleteReserveSlice = createAsyncThunk(
  "reserve/deleteReserve",
  async ({ reserveId, moveToNext, onFailure }, thunkAPI) => {
    try {
      const response = await deleteReserve(reserveId);
      if (moveToNext) {
        moveToNext(response);
      }
      return response;
    } catch (error) {
      if (onFailure) {
        onFailure(error.message);
      }

      if (error?.message === "Request failed with status code 403") {
        localStorage.removeItem("serviceToken");
        localStorage.removeItem("my_finance_user");
        window.location.replace("/");
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const reserveSlice = createSlice({
  name: "reserve",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutSlice: (state) => {
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
      state.user = null;
      state.isSuccess = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.isLoginSuccess = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getReserveListSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getReserveListSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.reserveLists = action.payload;
        state.reserveDetails = null;
      })
      .addCase(getReserveListSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(getReserveDetailsSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getReserveDetailsSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.reserveDetails = action.payload;
      })
      .addCase(getReserveDetailsSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        state.reserveDetails = null;
      })

      .addCase(createReserveSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(createReserveSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(createReserveSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(updateReserveSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateReserveSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(updateReserveSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })

      .addCase(deleteReserveSlice.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(deleteReserveSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
      })
      .addCase(deleteReserveSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { setUser, reset, logoutSlice } = reserveSlice.actions;

export default reserveSlice.reducer;
